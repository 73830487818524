﻿import { html, css, LitElement } from 'lit';
import router from '../../router';
import { customElement, property } from 'lit/decorators.js';

// If href is used, route, params, query, and hash are ignored.
@customElement('weekly-link')
export default class WeeklyLink extends LitElement {
  static get styles() {
    return css`
      a {
        color: inherit;
        text-decoration: inherit;
      }
    `;
  }

  @property({ type: String })
  /* eslint-disable-next-line no-undef */
  accessor href;

  @property({ type: String })
  /* eslint-disable-next-line no-undef */
  accessor route;

  @property({ type: Object })
  /* eslint-disable-next-line no-undef */
  accessor params = {};

  @property({ type: Object })
  /* eslint-disable-next-line no-undef */
  accessor query = {};

  @property({ type: String })
  /* eslint-disable-next-line no-undef */
  accessor hash;

  @property({ type: Boolean, attribute: 'new-window' })
  /* eslint-disable-next-line no-undef */
  accessor newWindow;

  static router = undefined;

  constructor() {
    super();

    this.addEventListener('click', this.handleClick);
  }

  render() {
    const href = this.href
      ?? router.generate(this.route ?? router.resolve().route.name, this.params, this.query, this.hash);

    return html`
      <a href=${href} @click=${this.handleClick}>
        <slot></slot>
      </a>
    `;
  }

  handleClick = event => {
    const target = event.currentTarget.shadowRoot?.querySelector('a') || event.currentTarget;

    event.stopPropagation();
    event.preventDefault();

    if (this.newWindow) {
      window.open(target, '_blank', 'noreferrer=true');
      return;
    }

    router.navigate(target.href.replace(document.baseURI, ''));
  };
}